<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">
                                        {{$t('title.loadVolumes')}}
                                        <span v-if="pagination.total">({{pagination.total}})</span>
                                    </template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button @click="setOperation('add')" variant="info" size="sm"
                                                      :title="$t('button.title.addNewItem')"
                                                      v-if="$global.hasPermission('pricesstore')" v-b-tooltip.hover>
                                                <i class="fe fe-plus"></i> {{$t('button.addNew')}}
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('pricesview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.runCommand')"
                                                      variant="outline-danger"
                                                      :disabled="global.pendingRequests > 0"
                                                      @click="handleCommandRunClick" v-b-tooltip.hover>
                                                <clip-loader style="display: inline" :loading="true" color="#fff" size="12px"
                                                             v-if="global.pendingRequests > 0">
                                                </clip-loader>
                                                <template v-if="global.pendingRequests <= 0">
                                                    <i class="fa fa-send-o"></i>
                                                </template>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="prices-table">
                    <b-table hover responsive
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             ref="orderTable"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(id)="{ detailsShowing, item, index, rowSelected }">
                            {{index + 1}}
                        </template>
                        <template v-slot:cell(from_date)="record">
                            {{ $global.dateFormat(record.item.from_date, "DD.MM.YYYY") }}
                        </template>
                        <template v-slot:cell(route_id)="record">
                            {{ (record.item.route ? record.item.route.name : '') }}
                        </template>
                        <template v-slot:cell(supplier_id)="record">
                            <h4 class="mr-1 mb-1 ant-tag ant-tag-blue"  v-for="(value, index) in record.item.volume_markets">
                                {{value.supplier_carrier.name}}
                            </h4>
                        </template>
                        <template v-slot:cell(country_id)="record">
                            <h4 class="ant-tag ant-tag-blue mr-1 mb-1"  v-for="(value, index) in record.item.volume_markets">
                                {{value.country.name}}
                                <strong>{{value.volume}}</strong>
                            </h4>
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('edit', record.item.id)"
                               :title="$t('button.title.editItem')" v-if="$global.hasPermission('pricesupdate')"
                               v-b-tooltip.hover>
                                <i class="fe fe-edit"></i>
                            </a>
                            <a-popconfirm title="Are you sure？" @confirm="handleDeleteClick(record.item.id)"
                                          v-if="$global.hasPermission('pricesdestroy')">
                                <i slot="icon" class="fe fe-trash"></i>
                                <a class=" ml-1"
                                   :title="$t('button.title.deleteItem')"
                                   v-b-tooltip.hover>
                                    <i class="fe fe-trash"></i>
                                </a>
                            </a-popconfirm>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                           size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.prices-table-->
                <div class="prices-operation">
                    <a-drawer
                        placement="right"
                        :width="'86%'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="handleOperationClose"
                        :visible="operation !== null && operation !== 'detail'"
                        :zIndex="10"
                        :title="$t(operationTitle)"
                    >
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row>
                                <b-col cols="12" v-show="global.pendingRequests > 0">
                                    <a-skeleton active :paragraph="{ rows: 10 }"/>
                                </b-col>
                                <b-col cols="12" v-show="global.pendingRequests <= 0">
                                    <b-row>
                                        <b-col sm="6">
                                            <b-form-group
                                                :label="$t('input.route')+' *'"
                                                label-for="route_id"
                                                :invalid-feedback="formErrors.first('route_id')">
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.routes"
                                                    placeholder=""
                                                    v-model="formFields.route_id"
                                                    :class="[{'invalid is-invalid': (formErrors.has('route_id'))}]"
                                                    @select="handleChangeRoute"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="6">
                                            <b-form-group
                                                    :label="$t('input.fromDate')+ '(System will fetch the inbound loads which are created after or equal to selected date)'"
                                                    label-for="fromDate"
                                                    :invalid-feedback="formErrors.first('from_date')">
                                                <b-form-datepicker
                                                        placeholder=""
                                                        :state="((formErrors.has('from_date') ? false : null))"
                                                        id="fromDate" v-model="formFields.from_date"
                                                        :locale="settings.locale" class="mb-2" reset-button></b-form-datepicker>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                    </b-row><!--/b-row-->
                                    <b-row class="py-1 bg-gray-1 mb-3" v-if="formErrors.has('volume')">
                                        <b-col sm="12">
                                            <span class="text-danger">
                                                {{formErrors.first('volume')}}
                                            </span>
                                        </b-col>
                                    </b-row><!--/b-row-->
                                    <b-row class="py-1 bg-gray-1 mb-3" v-for="(val, inx) in formFields.items" :key="inx">
                                        <b-col sm="12">
                                            <strong class="mr-2">{{$t('title.brands')}}:</strong>
                                            <span class="ant-tag ant-tag-green mr-1 mb-1" v-for="(valBrand, iBrand) in val.brands">{{valBrand.title}}</span>
                                        </b-col>
                                        <b-col sm="12">
                                            <strong class="mr-2">{{$t('title.models')}}:</strong>
                                            <span class="ant-tag ant-tag-blue mr-1 mb-1" v-for="(valModel, iBrand) in val.models">
                                                {{valModel.title}} {{valModel.version_code}}
                                            </span>
                                        </b-col>
                                        <b-col sm="12" md="4">{{val.supplier_name}}</b-col>
                                        <b-col sm="12" md="8" class="mt-2">
                                            <b-row>
                                                <template v-for="(v, i) in val.markets">
                                                    <b-col sm="6">
                                                        <strong v-if="i <= 0">{{$t('title.market')}}:</strong>
                                                        <div class="mb-1">{{v.market}}</div>
                                                    </b-col>
                                                    <b-col sm="6">
                                                        <strong v-if="i <= 0">{{$t('input.volume')}} %:</strong>
                                                        <b-form-input type="number"
                                                              @focus="$event.target.select()"
                                                              @blur="() => calculateCountryVolumes(v.market)"
                                                              class="form-control form-control-sm mb-1"
                                                              v-model="v.volume" min="0"></b-form-input>
                                                    </b-col>
                                                    <hr>
                                                </template>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                    <b-row class="py-1 bg-gray-1 mb-3" v-if="formFields.items.length > 0">
                                        <b-col sm="12" md="12" v-for="(val, inx) in countries" v-if="countries.length > 0" :key="inx">

                                            <div class="mb-1 text-right">
                                                <h6><strong>{{val.name}}:</strong>
                                                    <span v-if="val.volume > 100" class="text-danger">{{val.volume}}</span>
                                                    <span v-else>{{val.volume}}</span> %
                                                </h6>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col><!--/b-col-->
                                <div class="drawer-footer">
                                    <b-button
                                        size="sm"
                                        type="submit"
                                        variant="primary"
                                        :disabled="global.pendingRequests > 0"
                                        v-b-tooltip.hover :title="$t('button.title.save')"
                                    >
                                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                                     size="12px"
                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                        <i class="fa fa-save mr-1"></i>
                                        {{$t('button.save')}}
                                    </b-button>
                                    <b-button variant="warning" class="ml-3"
                                              size="sm" @click="handleOperationClose()"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </div>
                            </b-row><!--/b-row-->
                        </form><!--/form-->
                    </a-drawer>
                </div><!--/.prices-operation-->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.route')+' *'">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.routes"
                                            placeholder=""
                                            v-model="filters.routes"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker reset-button placeholder="" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12" class="mb-3">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker reset-button placeholder="" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'"></div><!-- /.detail -->
    </div>
</template>
<script>
    import ListingMixin from '../../../util/ListingMixin'
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import Vue from 'vue'
    import {request} from '../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'

    const FORM_STATE = {
        route_id: null,
        from_date: null,
        items: [],
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        routes: [],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: '#',
            key: 'id',
            sortable: false,
        },
        {
            label: self.$t('column.route'),
            key: 'route_id',
            sortable: true,
        },
        {
            label: self.$t('column.fromDate'),
            key: 'from_date',
            sortable: true,
        },
        {
            label: self.$t('column.total'),
            key: 'total',
            sortable: true,
        },
        {
            label: self.$t('column.supplier'),
            key: 'supplier_id',
            sortable: false,
        },
        {
            label: self.$t('column.country'),
            key: 'country_id',
            sortable: false,
        },
        (self.$global.hasAnyPermission(['pricesupdate', 'pricesdestroy'])
        ? {
            label: self.$t('column.action'),
            class: 'text-right',
            key: 'action',
            width: 150,
        } : {}),
    ];

    export default {
        mixins: [ListingMixin],
        components: {
            Treeselect
        },
        data() {
            return {
                operationTitle: 'title.loadVolumes',
                formFields: {...FORM_STATE},
                countries: {},
                filters: {...FILTER_STATE},
                listUrl: 'prices/volumes',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    routes: [],
                },
                show: true,
            }
        },
        mounted() {
            this.getRoutes();

            if (this.$route.query && this.$route.query.operation && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }

            this.$title = this.$t('topBar.navigations.modules.loadVolumes')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addLoadVolume' : 'title.editLoadVolume')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                const self = this
                let error = false
                this.formFields.items.map((item) => {
                    item.markets.map(i => {
                        if(!i.volume || i.volume <= 0) {
                            this.formErrors = new Error({volume: [self.$t('msc.requiredFieldsMissing')]})
                            error = true
                        }
                    })
                })

                if(error)
                    return;

                try {
                    const response = await request({
                        url: this.formFields.id ? 'prices/volumes/update' : 'prices/volumes/create',
                        method: 'post',
                        data: {
                            ...this.formFields,
                            from_date: this.formFields.from_date
                        },
                    })

                    if (this.formFields.id) {
                        this.itemUpdated()
                    } else {
                        this.itemAdded()
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleDeleteClick(id) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/prices/volumes/delete',
                        data: {
                            id: id,
                        },
                    })
                    this.loadList(this.listQueryParams)
                    this.itemDeleted()
                } catch (errors) {
                    this.itemDeleteFails()
                }
            },
            async handleEditClick(id) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/prices/volumes/detail/${id}`,
                    })

                    this.operationTitle = this.$t('title.editLoadVolume')
                    const {data} = response
                    const {volume_markets} = data
                    this.handleChangeRoute({id: data.route.id, volume: volume_markets})
                    this.formFields = {...FORM_STATE, id: data.id, route_id: data.route.id, from_date: this.$global.utcDateToLocalDate(data.from_date, "YYYY-MM-DD", "YYYY-MM-DD")}
                } catch (e) {
                    this.itemEditFails()
                    this.formFields = {...FORM_STATE}
                }
            },
            async getRoutes() {
                try {
                    const response = await request({
                        url: '/dropdowns/load/volume/routes',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.routes = data
                } catch (e) {
                    this.dropdowns.routes = []
                }
            },
            hasListAccess() {
                return this.$global.hasPermission('pricesview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            async handleCommandRunClick() {
                try {
                    const response = await request({
                        url: '/auto/inbound/rules/send',
                        method: "post"
                    })
                } catch (e) {
                    this.serverError()
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
            async handleChangeRoute(node, instanceId) {
                if(!node || !node.id) {
                    return;
                }

                try {
                    const response = await request({
                        url: `prices/volumes/routes/prices/${node.id}`,
                        params: {
                            ignore_request: 1
                        }
                    })

                    const {data} = response
                    this.formFields.items = data
                    this.countries = [];
                    if(node && node.volume) {
                        _.map(this.formFields.items, (value, index) => {
                            _.map(value.markets, (val, inx) => {
                                _.map(node.volume, (vVal, vInx) => {
                                    if(!_.find(this.countries, {name: vVal.country.name})) {
                                        this.countries.push({name: vVal.country.name, volume: 0});
                                    }

                                    if(vVal.country.name === val.market && value.supplier_id === vVal.supplier_carrier.id && value.price_id === vVal.price_id) {
                                        val.volume = vVal.volume
                                    }
                                })
                            })
                        })

                        const self = this
                        _.map(this.countries, (value, index) => self.calculateCountryVolumes(value.name))
                    }
                } catch (e) {

                }
            },
            calculateCountryVolumes(country) {
                let total = 0;
                _.map(this.formFields.items, (item, val) => {
                    _.map(item.markets, (i, v) => {
                        if(i.market === country) {
                            total += parseFloat(i.volume)
                        }
                    })
                })

                const index = _.findIndex(this.countries, {name: country});
                if(index >= 0)
                    this.countries[index].volume = total
            }
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
            total: function() {
                let total = 0;
                const items = this.formFields.items.map((item) => item.markets)
                _.map(items, (item) => item.map(i => total += parseInt(i.volume) ))
                return total;
            }
        },
    }
</script>
<style lang="scss">
    .min-24px {
        min-height: 20px;
    }

    @media screen and (max-width: 768px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }

        .min-24px {
            min-height: 24px !important;
        }
    }

    .time-label {
        margin: 0;
        font-size: 10px;
        position: absolute;
        top: -16px;
    }
</style>
